import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams, ToastController, ActionSheetController, Platform, LoadingController } from '@ionic/angular';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/Camera/ngx';
import { File, FileEntry } from '@ionic-native/File/ngx';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { FilePath } from '@ionic-native/file-path/ngx';
import { Storage } from '@ionic/storage';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { RestService } from '../services/rest.service';
import { Router } from '@angular/router';

const STORAGE_KEY = 'slip-img';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.page.html',
  styleUrls: ['./address-modal.page.scss'],
})
export class AddressModalPage implements OnInit {

  name: '';
  tel: '';
  email: '';
  address: '';
  userId: '';

  images = [];

  filename = '';

  public isUpload = false;

  cart: any;
  order: any;
  orderNo: any;

  paymentType = '';

  jsonCreToken = {
    card: {
      name: 'ทดสอบ จ่ายเงิน',
      number: '4242424242424242',
      expiration_month: '1',
      expiration_year: '2022',
      security_code: '123',
      city: '',
      postal_code: '',
    }
  };

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private camera: Camera,
    private file: File,
    private http: HttpClient,
    private toastController: ToastController,
    private actionSheetController: ActionSheetController,
    private plt: Platform,
    private filePath: FilePath,
    private storage: Storage,
    private ref: ChangeDetectorRef,
    private webview: WebView,
    private loadingController: LoadingController,
    private restApi: RestService,
    private router: Router
  ) {

    this.cart = navParams.get('cart');
    this.order = navParams.get('order');

    console.log(this.cart);
    console.log(this.order);

    this.storage.get('user').then((val) => {
      console.log(val);
      if (val) {
        // this.userData = val;
        this.name = val.name;
        this.tel = val.tel;
        this.email = val.email;
        this.address = val.address;
        this.userId = val.user_id;
      }
    });

  }

  close() {
    this.modalController.dismiss();
  }

  ngOnInit() {
  }

  save() {
    console.log(this.name);
    if (this.name !== '' && this.tel !== '' && this.email !== '' && this.address !== '') {

    }
  }

  startUpload(imgEntry) {


    this.file.resolveLocalFilesystemUrl(imgEntry.filePath)
      .then(entry => {
        (<FileEntry>entry).file(file => this.readFile(file))
      })
      .catch(err => {
        this.presentToast('Error while reading file.');
      });

  }

  readFile(file: any) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const formData = new FormData();
      const imgBlob = new Blob([reader.result], {
        type: file.type
      });
      formData.append('file', imgBlob, file.name);
      this.uploadImageData(formData, file.name);
    };
    reader.readAsArrayBuffer(file);
  }

  async uploadImageData(formData: FormData, filename: any) {
    this.filename = filename;
    const loading = await this.loadingController.create({
      message: 'Uploading image...',
    });
    await loading.present();
    console.log(formData.get + ' fdf  ' + this.images[0]);
    this.isUpload = true;
    console.log(this.isUpload + "XXXXX");

    this.http.post('https://goldenbettaapp.com/GoldenBetta/api/uploadSlip.php', formData)
      .pipe(
        finalize(() => {
          loading.dismiss();
          this.isUpload = true;
          // this.auth.uploadEvidence(this.user, filename).subscribe((res) => {
          //   this.presentToast('อัพโหลดหลักฐานเรียบร้อย');
          //   this.router.navigateByUrl('menu/main');
          // });
        })
      )
      .subscribe(res => {
        if (res['success']) {
          this.presentToast('File upload complete.');

        } else {
          this.presentToast('File upload failed.');
        }
      });
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      position: 'bottom',
      duration: 3000
    });
    toast.present();
  }

  async selectImage() {
    if (this.images.length === 0) {

      const actionSheet = await this.actionSheetController.create({
        header: 'Select Image source',
        buttons: [{
          text: 'Load from Library',
          handler: () => {
            this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
          }
        },
        {
          text: 'Use Camera',
          handler: () => {
            this.takePicture(this.camera.PictureSourceType.CAMERA);
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
        ]
      });

      await actionSheet.present();
    } else {
      alert('กรุณาลบรูปภาพเดิมทิ้งก่อน');
    }
  }

  takePicture(sourceType: PictureSourceType) {
    const options: CameraOptions = {
      quality: 100,
      sourceType: sourceType,
      saveToPhotoAlbum: false,
      correctOrientation: true
    };

    this.camera.getPicture(options).then(imagePath => {
      if (this.plt.is('android') && sourceType === this.camera.PictureSourceType.PHOTOLIBRARY) {
        this.filePath.resolveNativePath(imagePath)
          .then(filePath => {
            const correctPath = filePath.substr(0, filePath.lastIndexOf('/') + 1);
            const currentName = imagePath.substring(imagePath.lastIndexOf('/') + 1, imagePath.lastIndexOf('?'));
            this.copyFileToLocalDir(correctPath, currentName, this.createFileName());
          });
      } else {
        const currentName = imagePath.substr(imagePath.lastIndexOf('/') + 1);
        const correctPath = imagePath.substr(0, imagePath.lastIndexOf('/') + 1);
        this.copyFileToLocalDir(correctPath, currentName, this.createFileName());
      }
    });

  }


  copyFileToLocalDir(namePath, currentName, newFileName) {
    this.file.copyFile(namePath, currentName, this.file.dataDirectory, newFileName).then(success => {
      this.updateStoredImages(newFileName);
    }, error => {
      this.presentToast('Error while storing file.');
    });
  }

  updateStoredImages(name) {
    this.storage.get(STORAGE_KEY).then(images => {
      const arr = JSON.parse(images);
      if (!arr) {
        const newImages = [name];
        this.storage.set(STORAGE_KEY, JSON.stringify(newImages));
      } else {
        arr.push(name);
        this.storage.set(STORAGE_KEY, JSON.stringify(arr));
      }

      const filePath = this.file.dataDirectory + name;
      const resPath = this.pathForImage(filePath);

      const newEntry = {
        name: name,
        path: resPath,
        filePath: filePath
      };

      this.images = [newEntry, ...this.images];
      this.ref.detectChanges(); // trigger change detection cycle
    });
  }

  pathForImage(img) {
    if (img === null) {
      return '';
    } else {
      const converted = this.webview.convertFileSrc(img);
      return converted;
    }
  }

  createFileName() {
    const d = new Date(),
      n = d.getTime(),
      newFileName = n + '.jpg';
    return newFileName;
  }

  deleteImage(imgEntry, position) {
    this.images.splice(position, 1);

    this.storage.get(STORAGE_KEY).then(images => {
      const arr = JSON.parse(images);
      const filtered = arr.filter(name => name !== imgEntry.name);
      this.storage.set(STORAGE_KEY, JSON.stringify(filtered));

      const correctPath = imgEntry.filePath.substr(0, imgEntry.filePath.lastIndexOf('/') + 1);

      this.file.removeFile(correctPath, imgEntry.name).then(res => {
        this.presentToast('File removed.');
      });
    });
  }

  onClickPay() {
    if (this.name !== '' && this.name && this.tel !== '' && this.tel
      && this.email !== '' && this.email && this.address !== '' && this.address) {
      if (this.isUpload) {
        const createOrderData = {
          total_price: this.order.total_price,
          total_weight: this.order.total_weight,
          total_delivery_price: this.order.total_delivery_price,
          name: this.name,
          tel: this.tel,
          email: this.email,
          address: this.address,
          filename: this.filename,
          userId: this.userId
        };

        this.restApi.createOrder(createOrderData).then(data => {
          if (data) {
            this.orderNo = data;

            for (const entry of this.cart) {
              entry.order_no = this.orderNo;

            }
            this.restApi.saveProductOrders(this.cart).then(res => {
              if (res) {
                this.storage.remove('cart');
                alert('หมายเลขคำสั่งซื้อของคุณคือ ' + this.orderNo);

                let currentOrders: any;
                this.storage.get('myorders').then((val) => {
                  if (val) {
                    currentOrders = val;
                    currentOrders.push(this.orderNo);
                    this.storage.set('myorders', currentOrders).then(() => {
                      this.router.navigate(['/myorder']);
                    });
                  } else {
                    currentOrders = [];
                    currentOrders.push(this.orderNo);
                    this.storage.set('myorders', currentOrders).then(() => {
                      this.router.navigate(['/myorder']);
                    });
                  }

                  this.modalController.dismiss();

                });

                // this.router.navigate(['/home']);
              } else {
                console.log('wrong');
              }
            });

          } else {
            console.log('wrong');
          }
        });
      } else {
        alert('กรุณาเลือกรูปภาพและกด อัพโหลดรูปภาพ');
      }

    } else {
      alert('กรุณากรอกข้อมูลให้ครบถ้วน');
    }
  }

  onClickChangeType(type) {
    this.paymentType = type;
  }

  payByVISA() {
    const jsonCreCharge = {
      amount: '',
      currency: 'thb',
      card: '',
      description: '',
      capture: true,
      return_uri: 'http://www.example.com/orders/3947/complete',
    };

    jsonCreCharge.amount = '' + (this.order.total_price + this.order.total_delivery_price) * 100;
    jsonCreCharge.description = '';
    this.restApi.createTokenCharge(this.jsonCreToken, jsonCreCharge).then((json) => {
      console.log(json);
      let jsonRes: any;
      jsonRes = JSON.parse(json);
      console.log(jsonRes);
      if (jsonRes.object === 'charge') {
        console.log(jsonRes);
      }

    }, (err) => {
      console.log(err);
      // this.jsonErr = JSON.stringify(err);
      alert(err.error.message);

    });
  }

}
